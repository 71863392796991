<template>
    <div class="table">
        <el-table id="dataTable" :data="tableData" :border="border" :stripe="stripe" style="width: 100%;height:100%"
            height="500" :default-sort="defaultSort" :sortable="true" v-loading="isLoading" :highlight-current-row="true"
            :row-class-name="rowClassName" @selection-change="handleSelectionChange">
            <slot></slot>
            <template #empty>
                <el-empty description="暂无数据，请查询" :image-size="300" />
            </template>
        </el-table>
    </div>
    <div class="pagination" v-if="isShowPagination">
        <el-pagination v-model:currentPage="currentPage" v-model:page-size="pageSize" :background="true"
            layout="prev,next, jumper, slot" :total="total" :hide-on-single-page="false" v-if="isSimplePagination">
            <el-icon @click="refresh" style="cursor:pointer">
                <Refresh />
            </el-icon>
        </el-pagination>
        <el-pagination v-model:currentPage="currentPage" v-model:page-size="pageSize" :background="true"
            layout="total,prev, pager, next, jumper, slot" :total="total" :hide-on-single-page="false" v-else>
            <el-icon @click="refresh" style="cursor:pointer">
                <Refresh />
            </el-icon>
        </el-pagination>
    </div>
</template>

<script>
import { Refresh } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus'
import request from '@/request/request'
export default {
    emits: ['rowClassName', 'selectionRows'],
    props: {
        tableDataUrl: {
            default: ''
        },
        tableDataOrigin: {
            default: ''
        },
        border: {
            default: false
        },
        stripe: {
            default: false
        },
        defaultSort: {
            default: {}
        },
        isShowPagination: {
            type: Boolean,
            default: true
        },
        isSimplePagination: {
            type: Boolean,
            default: true
        },
        isLoadData: {
            type: Boolean
        },
    },
    components: {
        Refresh
    },
    data() {
        return {
            tableData: [],
            pageSize: 15,
            currentPage: 1,
            total: this.isSimplePagination ?  99999999999999 : 0,
            params: {},
            isLoading: false,
            customLoad: false,
            requestUrl: '',
        }
    },
    watch: {
        currentPage(page) {
            this.showPage(page)
        }
    },
    mounted() {
        this.showPage()
    },
    methods: {
        async showPage(page = 1) {
            if (this.customLoad || this.isLoadData) {
                this.isLoading = true

                /**
                 * 直接传入数据，不请求
                 */
                if (this.tableDataOrigin) {
                    this.tableData = this.tableDataOrigin
                    this.isLoading = false
                }

                let params = {}
                if (this.isShowPagination) {
                    params = Object.assign(this.params, { pageSize: this.pageSize, page: page, current: this.currentPage })
                } else {
                    params = this.params
                }

                /**
                 * 传入的request请求方法
                 */
                if (this.tableDataUrl) {
                    await this.tableDataUrl(params).then(r => {
                        this.setTableDataList(r)
                    })
                }

                /**
                 * 传入的url地址
                 */
                if (this.requestUrl) {
                    await request.getAsync(this.requestUrl, this.params).then(r => {
                        this.setTableDataList(r)
                    })
                }
            }
        },
        refresh() {
            this.showPage(this.currentPage)
        },
        search(params) {
            this.params = params
            this.showPage()
        },
        setTableData(data) {
            this.tableData = data
            this.isLoading = false
        },
        setRequestUrl(url) {
            this.requestUrl = url
        },
        setCustomLoad(load) {
            this.customLoad = load
        },
        setParams(params) {
            this.search(params)
        },
        setTableDataList(response) {
            if (response && response.status == 200) {
                this.tableData = response.data
                if (this.isShowPagination && response.pagination && !this.isSimplePagination) {
                    this.pageSize = response.pagination.perPage
                    this.currentPage = response.pagination.currentPage
                    this.total = response.pagination.total
                }
            } else {
                ElMessage({
                    message: '请求错误',
                    type: 'error'
                })
            }
            this.isLoading = false
        },
        rowClassName(row) {
            this.$emit('rowClassName', row)
        },
        handleSelectionChange(rows) {
            this.$emit('selectionRows', rows)
        }
    }
}
</script>

<style scoped>
.pagination {
    margin-top: 30px;
}
</style>
